var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-form",
        { attrs: { readonly: !_vm.canEditSettings } },
        [
          _c("v-card-title", [_vm._v("Trip Request Form Messages")]),
          [
            _c(
              "v-expansion-panels",
              { staticClass: "my-2", attrs: { popout: "" } },
              [
                _c(
                  "v-expansion-panel",
                  [
                    _c(
                      "v-expansion-panel-header",
                      {
                        staticClass:
                          "text-uppercase font-weight-medium grey lighten-3",
                      },
                      [_vm._v(" General ")]
                    ),
                    _c(
                      "v-expansion-panel-content",
                      [
                        _c(
                          "v-row",
                          { staticClass: "my-2", attrs: { dense: "" } },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "12" } },
                              [
                                _c("v-textarea", {
                                  attrs: {
                                    outlined: "",
                                    rows: "1",
                                    "auto-grow": "",
                                    label: "General Trip Message",
                                    "prepend-inner-icon":
                                      "mdi-language-markdown",
                                  },
                                  model: {
                                    value: _vm.messages.general,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.messages, "general", $$v)
                                    },
                                    expression: "messages.general",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "12" } },
                              [
                                _c("v-textarea", {
                                  attrs: {
                                    outlined: "",
                                    rows: "1",
                                    "auto-grow": "",
                                    label: "Comment Section Message",
                                  },
                                  model: {
                                    value: _vm.messages.comments,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.messages, "comments", $$v)
                                    },
                                    expression: "messages.comments",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-expansion-panel",
                  [
                    _c(
                      "v-expansion-panel-header",
                      {
                        staticClass:
                          "text-uppercase font-weight-medium grey lighten-3",
                      },
                      [_vm._v(" Leave / Return ")]
                    ),
                    _c(
                      "v-expansion-panel-content",
                      [
                        _c(
                          "v-row",
                          { staticClass: "my-2", attrs: { dense: "" } },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "12" } },
                              [
                                _c("v-textarea", {
                                  attrs: {
                                    outlined: "",
                                    rows: "1",
                                    "auto-grow": "",
                                    label:
                                      "Message for the Create Recurring Trips button",
                                  },
                                  model: {
                                    value: _vm.messages.recurringButton,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.messages,
                                        "recurringButton",
                                        $$v
                                      )
                                    },
                                    expression: "messages.recurringButton",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-expansion-panel",
                  [
                    _c(
                      "v-expansion-panel-header",
                      {
                        staticClass:
                          "text-uppercase font-weight-medium grey lighten-3",
                      },
                      [_vm._v(" Destination ")]
                    ),
                    _c(
                      "v-expansion-panel-content",
                      [
                        _c("v-row", {
                          staticClass: "my-2",
                          attrs: { dense: "" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-expansion-panel",
                  [
                    _c(
                      "v-expansion-panel-header",
                      {
                        staticClass:
                          "text-uppercase font-weight-medium grey lighten-3",
                      },
                      [_vm._v(" Attendees ")]
                    ),
                    _c(
                      "v-expansion-panel-content",
                      [
                        _c(
                          "v-row",
                          { staticClass: "my-2", attrs: { dense: "" } },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "12" } },
                              [
                                _c("v-textarea", {
                                  attrs: {
                                    outlined: "",
                                    rows: "1",
                                    "auto-grow": "",
                                    label: "Message for Health Concerns",
                                  },
                                  model: {
                                    value: _vm.messages.healthConcerns,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.messages,
                                        "healthConcerns",
                                        $$v
                                      )
                                    },
                                    expression: "messages.healthConcerns",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "12" } },
                              [
                                _c("v-textarea", {
                                  attrs: {
                                    outlined: "",
                                    rows: "1",
                                    "auto-grow": "",
                                    label:
                                      "Enter the message below that you would like to appear for students away during lunch",
                                  },
                                  model: {
                                    value: _vm.messages.awayForLunch,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.messages,
                                        "awayForLunch",
                                        $$v
                                      )
                                    },
                                    expression: "messages.awayForLunch",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "12" } },
                              [
                                _c("v-textarea", {
                                  attrs: {
                                    outlined: "",
                                    rows: "1",
                                    "auto-grow": "",
                                    label: "Message for Packed Lunch question",
                                  },
                                  model: {
                                    value: _vm.messages.packedLunch,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.messages, "packedLunch", $$v)
                                    },
                                    expression: "messages.packedLunch",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "12" } },
                              [
                                _c("v-textarea", {
                                  attrs: {
                                    outlined: "",
                                    rows: "1",
                                    "auto-grow": "",
                                    label:
                                      "Enter the message below that you would like to appear for students needing bagged lunches",
                                  },
                                  model: {
                                    value: _vm.messages.needLunch,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.messages, "needLunch", $$v)
                                    },
                                    expression: "messages.needLunch",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-expansion-panel",
                  [
                    _c(
                      "v-expansion-panel-header",
                      {
                        staticClass:
                          "text-uppercase font-weight-medium grey lighten-3",
                      },
                      [_vm._v(" Transportation ")]
                    ),
                    _c(
                      "v-expansion-panel-content",
                      [
                        _c(
                          "v-row",
                          { staticClass: "my-2", attrs: { dense: "" } },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "12" } },
                              [
                                _c("v-textarea", {
                                  attrs: {
                                    outlined: "",
                                    rows: "1",
                                    "auto-grow": "",
                                    label:
                                      "Guidelines for the number of students per bus on the trip form",
                                    hint: "Note: An example of this is '50 Elementary Students Per Bus.'",
                                  },
                                  model: {
                                    value: _vm.messages.studentsPerVehicle,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.messages,
                                        "studentsPerVehicle",
                                        $$v
                                      )
                                    },
                                    expression: "messages.studentsPerVehicle",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "12" } },
                              [
                                _c("v-textarea", {
                                  attrs: {
                                    outlined: "",
                                    rows: "1",
                                    "auto-grow": "",
                                    label:
                                      "Message that appears when no vehicles available",
                                  },
                                  model: {
                                    value: _vm.messages.noVehiclesAvailable,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.messages,
                                        "noVehiclesAvailable",
                                        $$v
                                      )
                                    },
                                    expression: "messages.noVehiclesAvailable",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-expansion-panel",
                  [
                    _c(
                      "v-expansion-panel-header",
                      {
                        staticClass:
                          "text-uppercase font-weight-medium grey lighten-3",
                      },
                      [_vm._v(" Funding ")]
                    ),
                    _c(
                      "v-expansion-panel-content",
                      [
                        _c(
                          "v-row",
                          { staticClass: "my-2", attrs: { dense: "" } },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "12" } },
                              [
                                _c("v-textarea", {
                                  attrs: {
                                    outlined: "",
                                    rows: "1",
                                    "auto-grow": "",
                                    label: "Allotment depleted message",
                                  },
                                  model: {
                                    value: _vm.messages.allotmentDepleted,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.messages,
                                        "allotmentDepleted",
                                        $$v
                                      )
                                    },
                                    expression: "messages.allotmentDepleted",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-expansion-panel",
                  [
                    _c(
                      "v-expansion-panel-header",
                      {
                        staticClass:
                          "text-uppercase font-weight-medium grey lighten-3",
                      },
                      [_vm._v(" Additional Information ")]
                    ),
                    _c(
                      "v-expansion-panel-content",
                      [
                        _c("v-row", {
                          staticClass: "my-2",
                          attrs: { dense: "" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-expansion-panel",
                  [
                    _c(
                      "v-expansion-panel-header",
                      {
                        staticClass:
                          "text-uppercase font-weight-medium grey lighten-3",
                      },
                      [_vm._v(" Miscellaneous ")]
                    ),
                    _c(
                      "v-expansion-panel-content",
                      [
                        _c(
                          "v-row",
                          { staticClass: "my-2", attrs: { dense: "" } },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "12" } },
                              [
                                _c("v-textarea", {
                                  attrs: {
                                    outlined: "",
                                    rows: "1",
                                    "auto-grow": "",
                                    label:
                                      "Message at Bottom of Trip Request Above Submit Button",
                                  },
                                  model: {
                                    value: _vm.messages.final,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.messages, "final", $$v)
                                    },
                                    expression: "messages.final",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "12" } },
                              [
                                _c("v-textarea", {
                                  attrs: {
                                    outlined: "",
                                    rows: "1",
                                    "auto-grow": "",
                                    label: "Trip Acceptance Message",
                                  },
                                  model: {
                                    value: _vm.messages.acceptance,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.messages, "acceptance", $$v)
                                    },
                                    expression: "messages.acceptance",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "12" } },
                              [
                                _c("v-textarea", {
                                  attrs: {
                                    outlined: "",
                                    rows: "1",
                                    "auto-grow": "",
                                    label: "Trip cancelation message",
                                  },
                                  model: {
                                    value: _vm.messages.tripCancelation,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.messages,
                                        "tripCancelation",
                                        $$v
                                      )
                                    },
                                    expression: "messages.tripCancelation",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "12" } },
                              [
                                _c("v-textarea", {
                                  attrs: {
                                    outlined: "",
                                    rows: "1",
                                    "auto-grow": "",
                                    label: "Request for Quote Message",
                                  },
                                  model: {
                                    value: _vm.messages.requestQuote,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.messages,
                                        "requestQuote",
                                        $$v
                                      )
                                    },
                                    expression: "messages.requestQuote",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }