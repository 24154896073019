<template>
  <v-container fluid>
    <v-form :readonly="!canEditSettings">
      <v-card-title>Trip Request Form Messages</v-card-title>
      <template>
        <v-expansion-panels popout class="my-2">
          <v-expansion-panel>
            <v-expansion-panel-header class="text-uppercase font-weight-medium grey lighten-3">
              General
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row dense class="my-2">
                <v-col cols="12" md="12">
                  <v-textarea
                    outlined
                    rows="1"
                    auto-grow
                    label="General Trip Message"
                    prepend-inner-icon="mdi-language-markdown"
                    v-model="messages.general"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="12">
                  <v-textarea
                    outlined
                    rows="1"
                    auto-grow
                    label="Comment Section Message"
                    v-model="messages.comments"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-uppercase font-weight-medium grey lighten-3">
              Leave / Return
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row dense class="my-2">
                <v-col cols="12" md="12">
                  <v-textarea
                    outlined
                    rows="1"
                    auto-grow
                    label="Message for the Create Recurring Trips button"
                    v-model="messages.recurringButton"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-uppercase font-weight-medium grey lighten-3">
              Destination
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row dense class="my-2"></v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-uppercase font-weight-medium grey lighten-3">
              Attendees
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row dense class="my-2">
                <v-col cols="12" md="12">
                  <v-textarea
                    outlined
                    rows="1"
                    auto-grow
                    label="Message for Health Concerns"
                    v-model="messages.healthConcerns"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="12">
                  <v-textarea
                    outlined
                    rows="1"
                    auto-grow
                    label="Enter the message below that you would like to appear for students away during lunch"
                    v-model="messages.awayForLunch"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="12">
                  <v-textarea
                    outlined
                    rows="1"
                    auto-grow
                    label="Message for Packed Lunch question"
                    v-model="messages.packedLunch"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="12">
                  <v-textarea
                    outlined
                    rows="1"
                    auto-grow
                    label="Enter the message below that you would like to appear for students needing bagged lunches"
                    v-model="messages.needLunch"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-uppercase font-weight-medium grey lighten-3">
              Transportation
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row dense class="my-2">
                <v-col cols="12" md="12">
                  <v-textarea
                    outlined
                    rows="1"
                    auto-grow
                    label="Guidelines for the number of students per bus on the trip form"
                    hint="Note: An example of this is '50 Elementary Students Per Bus.'"
                    v-model="messages.studentsPerVehicle"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="12">
                  <v-textarea
                    outlined
                    rows="1"
                    auto-grow
                    label="Message that appears when no vehicles available"
                    v-model="messages.noVehiclesAvailable"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-uppercase font-weight-medium grey lighten-3">
              Funding
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row dense class="my-2">
                <v-col cols="12" md="12">
                  <v-textarea
                    outlined
                    rows="1"
                    auto-grow
                    label="Allotment depleted message"
                    v-model="messages.allotmentDepleted"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-uppercase font-weight-medium grey lighten-3">
              Additional Information
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row dense class="my-2"></v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-uppercase font-weight-medium grey lighten-3">
              Miscellaneous
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row dense class="my-2">
                <v-col cols="12" md="12">
                  <v-textarea
                    outlined
                    rows="1"
                    auto-grow
                    label="Message at Bottom of Trip Request Above Submit Button"
                    v-model="messages.final"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="12">
                  <v-textarea outlined rows="1" auto-grow label="Trip Acceptance Message" v-model="messages.acceptance">
                  </v-textarea>
                </v-col>
                <v-col cols="12" md="12">
                  <v-textarea
                    outlined
                    rows="1"
                    auto-grow
                    label="Trip cancelation message"
                    v-model="messages.tripCancelation"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="12">
                  <v-textarea
                    outlined
                    rows="1"
                    auto-grow
                    label="Request for Quote Message"
                    v-model="messages.requestQuote"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { GET_CONFIG, SET_CONFIG } from '@/store/modules/Config/actions';
import promptBeforeLeave from '@/mixins/promptBeforeLeave';
import { cloneDeep } from 'lodash';

export default {
  inject: ['eventHub'],
  components: {},
  data: () => ({
    messages: {
      general: '',
      comments: '',
      recurringButton: '',
      final: '',
      acceptance: '',
      studentsPerVehicle: '',
      needLunch: '',
      awayForLunch: '',
      packedLunch: '',
      healthConcerns: '',
      allotmentDepleted: '',
      requestQuote: '',
      noVehiclesAvailable: '',
      tripCancelation: '',
    },
  }),
  computed: {
    ...mapGetters('user', ['me', 'canEditSettings']),
  },
  created() {
    this.eventHub.$on('saveMessagesConfig', () => this.save());
  },
  beforeDestroy() {
    this.eventHub.$off('saveMessagesConfig');
  },
  async mounted() {
    await this.getTRConfig();
  },
  methods: {
    ...mapActions('config', [GET_CONFIG, SET_CONFIG]),
    async getTRConfig() {
      this.trConfig = await this.getConfig('tripRequestForm');
      if (this.trConfig && this.trConfig.messages) this.messages = this.trConfig.messages;
      this.baseFormValue = cloneDeep(this.messages);
    },
    async save() {
      try {
        const value = this.trConfig ? { ...this.trConfig, messages: this.messages } : { messages: this.messages };
        const r = await this.setConfig({ name: 'tripRequestForm', value });
        if (r && r.done) {
          this.baseFormValue = cloneDeep(this.messages);
          this.$myalert.success('Trip Request Form Messages saved');
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
  },
  watch: {
    messages: {
      handler(value) {
        this.latestFormValue = cloneDeep(value);
      },
      deep: true,
    },
  },
  mixins: [promptBeforeLeave],
};
</script>

<style scoped>
.save-btn {
  float: right;
  margin-bottom: 12px;
}
</style>
